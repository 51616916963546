import { Greyscale, ScreenSize, SecondaryColor } from 'glints-aries';
import { Icon as AriesIcon, Typography } from 'glints-aries/lib/@next';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import styled from 'styled-components';

import GlintsContainer from 'src/components/GlintsContainer';
import { BookmarkButton } from 'src/modules/Bookmark';

export const Main = styled.div`
  width: 100%;
  background-color: ${Neutral.B99};
`;

export const Container = styled(GlintsContainer)`
  background-color: ${Neutral.B99};
  @media (min-width: ${ScreenSize.desktopL}px) {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
  @media (max-width: ${ScreenSize.tablet}px) {
    padding: 0px;
  }
`;

export const NewCard = styled.section`
  background: ${Greyscale.white};
  margin-top: 24px;
  width: 100%;
  grid-column: span 2;
  border: 1px solid #d4d5d8;
  box-shadow: 0px 0px 5px 0px #4747471a;
  border-radius: 8px;
  padding-bottom: 24px;

  @media (min-width: ${ScreenSize.desktopM}px) {
    max-width: 1360px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: ${ScreenSize.tablet}px) {
    padding-bottom: 20px;
    max-width: 100%;
    border-radius: 0px;
    box
  }
`;

export const NewCardHeaderLink = styled.a`
  color: ${SecondaryColor.actionblue};
  font-family: Noto Sans;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.75px;
  text-align: right;
  font-weight: bold;
  cursor: pointer;
`;

export const Header = styled.section`
  background: ${Greyscale.white};
  box-shadow: 0px -1px 0px 0px #d4d5d8 inset;
  padding-bottom: 24px;

  & + & {
    margin-top: 22px;
  }

  @media (min-width: ${ScreenSize.desktopS}px) {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'logo overview'
      'footer footer';
    padding: 40px;
  }

  @media (min-width: ${ScreenSize.desktopM}px) {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 100%;
    z-index: 2;
  }
`;

export const VerifiedBadge = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 24px;
  }

  @media (max-width: ${ScreenSize.tablet}px) {
    width: 20px;
    height: 20px;
  }
`;

export const Icon = styled(AriesIcon)`
  fill: ${SecondaryColor.actionblue};

  @media (max-width: ${ScreenSize.tablet}px) {
    width: 20px;
    height: 20px;
  }
`;

export const AttributeIcon = styled(Icon)`
  margin-left: 4px;
  fill: ${Greyscale.devilsgrey};
  height: 24px;
  width: 24px;
`;

export const Overview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 17px;

  @media (min-width: ${ScreenSize.desktopS}px) {
    grid-area: overview;
    margin-top: 0;
    margin-left: 30px;
  }

  @media (max-width: ${ScreenSize.desktopS}px) {
    gap: 0;
  }
`;

export const CompanyNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  @media (max-width: ${ScreenSize.tablet}px) {
    gap: 8px;
  }
`;

export const ShieldBadge = styled.img`
  width: 32px;
  height: 32px;

  @media (max-width: ${ScreenSize.tablet}px) {
    width: 20px;
    height: 20px;
  }
`;

export const Name = styled.h1`
  font-size: 26px;
  font-weight: bold;

  @media (min-width: ${ScreenSize.desktopS}px) {
    margin: 0;
    font-size: 30px;
  }
`;

export const Tagline = styled.p`
  font-size: 18px;
  margin-top: 10px;

  @media (min-width: ${ScreenSize.desktopS}px) {
    margin: 0;
  }
  @media (max-width: ${ScreenSize.tablet}px) {
    margin-top: 8px;
    margin-bottom: 20px;
    max-height: 180px;
  }
`;

export const VerifiedPopoverIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  vertical-align: sub;
`;

export const PopoverContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const PopoverTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CustomBookmarkButton = styled(BookmarkButton)`
  @media (max-width: ${ScreenSize.desktopS - 1}px) {
    padding: 4px;
    text-size: 14px;
    justify-content: flex-start;
  }

  [class*='BookmarkButtonsc__Icon'] {
    width: 24px;
    height: 24px;
  }
`;

export const VipTextSection = styled.div`
  display: flex;
  flex: 1 1 0%;
  align-items: start;
  gap: 16px;
  padding: 20px;

  background-color: ${Neutral.B99};

  @media (min-width: ${ScreenSize.desktopS}px) {
    margin-top: 0;
  }
`;

export const VipTextSectionBadgeImage = styled.img`
  width: 24px;
  height: 24px;
`;

export const VipTextRightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const VipTextHeadline = styled.h3`
  font-size: 20px;
  font-weight: bold;
`;

export const CardTitle = styled(Typography).attrs({
  variant: 'subtitle1',
  as: 'h2',
})`
  padding: 24px;
  box-shadow: 0 1px #d4d5d8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;

  @media (max-width: ${ScreenSize.mobileL}px) {
    padding: 12px 16px;
  }
`;

export const CardContent = styled.main`
  padding: 24px;
  padding-bottom: 0px;
  ::after {
    content: '';
    clear: both;
    display: block;
  }

  @media (max-width: ${ScreenSize.mobileL}px) {
    padding: 20px 16px 0px 16px;
  }
`;

export const GalleryContainer = styled.div`
  padding: 14px 24px 0px 24px;

  @media (max-width: ${ScreenSize.mobileL}px) {
    padding: 20px 16px 0px 16px;
  }
`;

export const SubGalleryContainer = styled(GalleryContainer)`
  padding-top: 0px;

  @media (max-width: ${ScreenSize.mobileL}px) {
    padding: 0px 16px 0px 16px;
  }
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  grid-template-rows: auto;
  gap: 16px;
  align-items: stretch;

  @media (max-width: ${ScreenSize.mobileL}px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
`;

export const EmptyCardContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  margin: 32px 0;
`;

export const JobList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const JobListItem = styled.li`
  list-style-type: none;
  padding: 20px 0;
  position: relative;

  & + &,
  :first-child {
    border-top: solid 1px #ddd;
  }

  :last-child {
    border-bottom: solid 1px #ddd;
  }
`;

export const JobTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

export const TitleSection = styled(Typography).attrs({
  variant: 'body2',
})`
  margin-bottom: 20px;
`;

export const LocationMap = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  align-items: center;
  margin-top: 12px;
  fill: ${SecondaryColor.actionblue};

  a {
    cursor: pointer;
  }

  Icon,
  && svg {
    flex-shrink: unset;
    flex-basis: 18px;
    width: 24px;
    height: 24px;
    margin-left: 0px;
  }
`;

export const TextBetweenLine = styled.div`
  display: flex;
  align-items: center;
  color: ${Blue.Brand};
  font-weight: bold;
  margin: 32px 0px;
  padding: 0 20px;

  ::before,
  ::after {
    content: '';
    flex: 1;
    height: 2px;
    background: linear-gradient(90deg, #6cc9ec00 0%, ${Blue.S54} 100%);
  }

  ::before {
    margin-right: 8px;
  }

  ::after {
    margin-left: 8px;
    transform: scaleX(-1);
  }
`;

export const CenterLoading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;
