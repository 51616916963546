import { ApolloError, useQuery } from '@apollo/client';
import { useRouter } from 'next/dist/client/router';

import isServer from 'src/common/isServer';
import { JobStatus } from 'src/global/models/Job';

import { getCompanyByIdQuery } from '../graphql';
import {
  CompanyDetailQueryResponse,
  CompanyDetailWithJobs,
} from '../interface';

export interface CompanyDetailResult {
  company?: CompanyDetailWithJobs;
  loading: boolean;
  error?: ApolloError;
}

export const useGetCompanyById = (): CompanyDetailResult => {
  const router = useRouter();
  const { id } = router.query;
  const { data, loading, error } = useQuery<CompanyDetailQueryResponse>(
    getCompanyByIdQuery,
    {
      variables: {
        id: id,
        data: {
          limit: 20,
          status: JobStatus.OPEN,
        },
      },
      fetchPolicy: isServer ? 'cache-first' : 'network-only',
    }
  );
  return { company: data?.getCompanyById, loading, error };
};
