import React, { Suspense, useMemo, useRef } from 'react';
import { Loading } from 'glints-aries';
import { Typography } from 'glints-aries/lib/@next';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { FormattedMessage } from 'react-intl';

import { tryQuery } from 'src/common/graphql/graphqlHelper';
import isServer from 'src/common/isServer';
import { getLocationData } from 'src/common/utils/hierarchicalLocationHelpers';
import { AssetType } from 'src/components/GlintsPicture/interfaces';
import { InView } from 'src/components/InView';
import { JobStatus } from 'src/global/models/Job';
import { EBreakpoints, Media } from 'src/media';
import { getCompanyByIdQuery } from 'src/modules/Company/graphql';
import { CompanyDetailQueryResponse } from 'src/modules/Company/interface';
import Error404Page from 'src/pages/Error404Page';

import {
  CardContent,
  CardTitle,
  CenterLoading,
  Container,
  GalleryContainer,
  Main,
  NewCard,
  SubGalleryContainer,
  TextBetweenLine,
  TitleSection,
} from './CompanyPage.sc';
import {
  CompanyTabTypes,
  TabListItems,
} from './components/TopFoldCompanySection/const';
import { getDescriptionRawAndImages, isDraftStateEmpty } from './helper';
import { useGetCompanyById } from './hooks/useGetCompanyById';

const Footer = dynamic(() => import('src/components/Footer/FooterPsychFlat'), {
  ssr: true,
});
const DraftjsReader = dynamic(
  () =>
    import('src/components/Editor/DraftjsReader').then(
      (mod) => mod.DraftjsReader
    ),
  {
    ssr: true,
  }
);
const Gallery = dynamic(() => import('src/components/Gallery'), {
  ssr: true,
});

const ContactSection = dynamic(
  import('src/modules/Company/components/ContactSection').then(
    (mod) => mod.ContactSection
  ),
  {
    ssr: true,
  }
);
const JobsSection = dynamic(
  import('src/modules/Company/components/JobsSection').then(
    (mod) => mod.JobsSection
  ),
  {
    ssr: true,
  }
);
const SimilarCompaniesSection = dynamic(
  import('src/modules/Company/components/SimilarCompaniesSection').then(
    (mod) => mod.SimilarCompaniesSection
  ),
  {
    ssr: true,
  }
);
const TopFoldCompanySection = dynamic(
  import('src/modules/Company/components/TopFoldCompanySection').then(
    (mod) => mod.TopFoldCompanySection
  ),
  {
    ssr: true,
  }
);

const TopJobsSection = dynamic(
  import('src/modules/Company/components/TopJobsSection').then(
    (mod) => mod.TopJobsSection
  ),
  {
    ssr: true,
  }
);

const _CompanyPage = () => {
  const topJobsSectionRef = useRef<HTMLElement | null>(null);
  const descriptionSectionRef = useRef<HTMLElement | null>(null);
  const cultureSectionRef = useRef<HTMLElement | null>(null);
  const contactSectionRef = useRef<HTMLElement | null>(null);
  const gallerySectionRef = useRef<HTMLElement | null>(null);
  const jobsSectionRef = useRef<HTMLElement | null>(null);
  const similarCompaniesSectionRef = useRef<HTMLElement | null>(null);

  const { company, loading, error } = useGetCompanyById();

  const tabToSectionRefMap: Record<
    CompanyTabTypes,
    React.RefObject<HTMLElement>
  > = useMemo(
    () => ({
      topJobsTab: topJobsSectionRef,
      descriptionTab: descriptionSectionRef,
      cultureTab: cultureSectionRef,
      contactTab: contactSectionRef,
      galleryTab: gallerySectionRef,
      jobsTab: jobsSectionRef,
      similarCompaniesTab: similarCompaniesSectionRef,
    }),
    []
  );

  const availableSectionRefs = useMemo(
    () => TabListItems.map((tab) => tabToSectionRefMap[tab]).filter(Boolean),
    [tabToSectionRefMap]
  );

  const scrollToJobsSection = () => {
    jobsSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToSimilarCompaniesSection = () => {
    similarCompaniesSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const topJobs = useMemo(
    () => company?.jobs?.slice(0, 3) || [],
    [company?.jobs]
  );
  const photos = useMemo(
    () =>
      company?.photosJsonString ? JSON.parse(company.photosJsonString) : [],
    [company?.photosJsonString]
  );

  const socialMediaSites = useMemo(
    () =>
      company?.socialMediaSitesJsonString
        ? JSON.parse(company.socialMediaSitesJsonString)
        : null,
    [company?.socialMediaSitesJsonString]
  );

  if (loading) {
    return (
      <CenterLoading>
        <Loading />
      </CenterLoading>
    );
  }

  if (error || !company) {
    return <Error404Page />;
  }

  const descriptionJson = company.descriptionJsonString
    ? JSON.parse(company.descriptionJsonString)
    : null;
  const {
    selectedDescriptionRaw: selectedCompanyDescriptionRaw,
    imagesInRaw: imagesInCompanyDescriptionRaw,
  } = getDescriptionRawAndImages(descriptionJson);
  const isDescriptionEmpty = isDraftStateEmpty(selectedCompanyDescriptionRaw);

  const cultureJson = company.cultureJsonString
    ? JSON.parse(company.cultureJsonString)
    : null;
  const {
    selectedDescriptionRaw: selectedCompanyCultureRaw,
    imagesInRaw: imagesInCompanyCulture,
  } = getDescriptionRawAndImages(cultureJson);
  const isCultureEmpty = isDraftStateEmpty(selectedCompanyCultureRaw);

  const relevantJobs = company.jobs;
  const industryName = company.industry?.name;

  const locationData =
    company.hierarchicalLocation &&
    getLocationData(company.hierarchicalLocation);
  const countryName = locationData?.countryName;
  const countryCode = locationData?.countryCode;

  return (
    <Main>
      <Suspense fallback={<Loading />}>
        <TopFoldCompanySection
          company={company}
          relevantJobsLength={relevantJobs?.length}
          sectionRefs={availableSectionRefs}
        />
      </Suspense>

      <Container>
        {topJobs?.length > 0 && (
          <TopJobsSection
            topJobs={topJobs}
            company={company}
            ref={topJobsSectionRef}
            scrollToSectionHandler={scrollToJobsSection}
          />
        )}

        <NewCard id="description-section" ref={descriptionSectionRef}>
          <CardTitle>
            <FormattedMessage
              id="company-page.overview-section.title2"
              defaultMessage="Company Details"
              values={{ companyName: company.name }}
            />
          </CardTitle>
          <CardContent>
            <TitleSection>
              <FormattedMessage
                id="company-page.overview-section.description.title"
                defaultMessage="Description"
              />
            </TitleSection>
            <Choose>
              <When condition={!isDescriptionEmpty}>
                <DraftjsReader draftjsObject={selectedCompanyDescriptionRaw} />
              </When>
              <Otherwise>
                <Typography variant="subtitle2">
                  <FormattedMessage
                    id="company-page.overview-section.empty"
                    defaultMessage="The company has not completed this section."
                  />
                </Typography>
              </Otherwise>
            </Choose>
          </CardContent>
          {imagesInCompanyDescriptionRaw && (
            <ImageGallery imagesURL={imagesInCompanyDescriptionRaw} />
          )}
        </NewCard>

        <NewCard id="culture-section" ref={cultureSectionRef}>
          <CardTitle>
            <FormattedMessage
              id="company-page.overview-section.culture.title"
              defaultMessage="Culture"
            />
          </CardTitle>

          <CardContent>
            <Choose>
              <When condition={!isCultureEmpty}>
                <DraftjsReader draftjsObject={selectedCompanyCultureRaw} />
              </When>
              <Otherwise>
                <Typography variant="subtitle2">
                  <FormattedMessage
                    id="company-page.overview-section.empty"
                    defaultMessage="The company has not completed this section."
                  />
                </Typography>
              </Otherwise>
            </Choose>
          </CardContent>

          {imagesInCompanyCulture && (
            <ImageGallery imagesURL={imagesInCompanyCulture} />
          )}
        </NewCard>

        {(socialMediaSites || company.address || company.website) && (
          <NewCard id="contact-section" ref={contactSectionRef}>
            <CardTitle>
              <FormattedMessage
                id="company-page.overview-section.contact.title"
                defaultMessage="Contact"
              />
            </CardTitle>
            <ContactSection company={company} />
          </NewCard>
        )}

        <NewCard id="gallery-section" ref={gallerySectionRef}>
          <CardTitle>
            <FormattedMessage
              id="company-page.gallery-section.title"
              defaultMessage="Gallery"
            />
          </CardTitle>
          <GalleryContainer>
            <ImageGallery imagesURL={photos} />
          </GalleryContainer>
        </NewCard>

        <NewCard id="jobs-section" ref={jobsSectionRef}>
          <CardTitle>
            <FormattedMessage
              id="company-page.jobs-section.title-v2"
              defaultMessage="Jobs"
              values={{ numberOfJobs: relevantJobs?.length }}
            />
          </CardTitle>
          <CardContent>
            <JobsSection
              relevantJobs={relevantJobs}
              company={company}
              scrollToSimilarCompaniesSection={scrollToSimilarCompaniesSection}
            />
          </CardContent>
        </NewCard>

        <TextBetweenLine>
          <Typography variant="body2">
            <FormattedMessage
              id="text-similar-companies-for-you"
              defaultMessage="Similar companies for you"
            />
          </Typography>
        </TextBetweenLine>

        <NewCard
          id="similar-companies-section"
          ref={similarCompaniesSectionRef}
        >
          <CardTitle>
            <FormattedMessage
              id="text-similar-industry-companies"
              defaultMessage="Similar {Industry} Companies in {Country}"
              values={{
                Industry: industryName,
                Country: countryName,
              }}
            />
          </CardTitle>
          <CardContent>
            <SimilarCompaniesSection
              companyId={company.id}
              countryCode={countryCode}
            />
          </CardContent>
        </NewCard>
      </Container>

      <InView>
        <Footer />
      </InView>
    </Main>
  );
};

const CompanyPage: NextPage<Record<string, unknown>> = _CompanyPage;

CompanyPage.getInitialProps = async ({ apolloClient, query, res }) => {
  if (!isServer || !res) {
    return {};
  }
  const { id } = query;
  const { response, error } = await tryQuery<CompanyDetailQueryResponse>({
    apolloClient: apolloClient,
    query: getCompanyByIdQuery,
    variables: {
      id: id,
      data: {
        limit: 20,
        status: JobStatus.OPEN,
      },
    },
  });
  if (response) {
    return {};
  }

  if (error) {
    console.error('Error fetching company details', error);
    if (error) {
      res.statusCode = 404;
    }
  }

  return {
    props: {},
  };
};

export default CompanyPage;

const ImageGallery = React.memo(({ imagesURL }: { imagesURL: string[] }) => {
  if (!imagesURL || imagesURL.length === 0) return null;

  return (
    <SubGalleryContainer>
      <Media greaterThanOrEqual={EBreakpoints.tablet}>
        <Gallery
          photos={imagesURL}
          size="large"
          showHeader={false}
          header={{
            altText: 'Company Gallery',
            assetType: AssetType.companyPhotos,
          }}
          currentScreen={'desktop'}
          renderEmptyGallery={() => <></>}
        />
      </Media>
      <Media lessThan={EBreakpoints.mobileL}>
        <Gallery
          photos={imagesURL}
          size="large"
          showHeader={true}
          header={{
            altText: 'Company Gallery',
            assetType: AssetType.companyPhotos,
          }}
          currentScreen={'mobile'}
          renderEmptyGallery={() => <></>}
        />
      </Media>
    </SubGalleryContainer>
  );
});

ImageGallery.displayName = 'ImageGallery';
