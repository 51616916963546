import { gql } from '@apollo/client';

import { JobFields, LocationFields } from '../Opportunity/graphql';

export const getSimilarCompanies = gql`
  query getSimilarCompanies($id: String!, $jobsData: GetJobsByCompanyInput) {
    getSimilarCompanies(id: $id) {
      id
      name
      logo
      IndustryId
      status
      isVIP
      city {
        name
      }
      country {
        name
      }
      hierarchicalLocation: location {
        name
        formattedName
        parents {
          formattedName
          name
          level
        }
      }
      industry {
        name
      }
      verificationTier {
        type
      }
      jobs(data: $jobsData) {
        id
        title
        shouldShowSalary
        type
        minYearsOfExperience
        maxYearsOfExperience
        educationLevel
        skills {
          skill {
            name
          }
        }
        salaries {
          minAmount
          maxAmount
          CurrencyCode
          salaryType
        }
      }
    }
  }
`;

export const getCompanyByIdQuery = gql`
  query getCompanyById($id: String!, $data: GetJobsByCompanyInput!) {
    getCompanyById(id: $id) {
      id
      name
      logo
      size
      website
      address
      descriptionJsonString
      cultureJsonString
      socialMediaSitesJsonString
      photosJsonString
      isVIP
      status
      verifiedAt
      tagline
      industry {
        name
      }
      hierarchicalLocation: location {
        ...LocationFields
      }
      verificationTier {
        type
      }
      jobs(data: $data) {
        id
        title
        status
        source
        createdAt
        updatedAt
        attachmentsJsonString
        type
        shouldShowSalary
        educationLevel
        skills {
          skill {
            id
            name
          }
          mustHave
        }
        country {
          code
          name
        }
        city {
          id
          name
        }
        company {
          id
          name
          logo
          IndustryId
        }
        salaries {
          id
          minAmount
          maxAmount
          salaryMode
          salaryType
          CurrencyCode
        }
        location {
          ...LocationFields
        }
        hierarchicalJobCategory {
          HierarchicalJobCategoryId: id
          name
          level
          parents {
            HierarchicalJobCategoryId: id
            name
            level
          }
        }
        ...JobFields
        company {
          status
          isVIP
        }
      }
    }
  }
  ${LocationFields}
  ${JobFields}
`;
